<template>
  <div class="row feed boxw mb-1 " >
    <div class=" box  ">
      <div style="padding:10px;position:relative">
        <div style="display: flex;
        justify-content: space-between;
        margin-bottom: 6px;
        padding-bottom:8px;
        border-bottom: 1px solid #887c7c;">
        <div><i class="fas fa-chevron-left" style="padding: 10px;"></i></div>
        <div>
          <p style="font-size: 1.2rem;
          margin: 0;
          margin-top: 4px;color:#4c4c54;font-weight:600">Laura Gilbert</p>
        </div>
        <div class=" mt-1 mb-1">
          <div class="profile-image profile-image-small">
            <img src="https://images.unsplash.com/photo-1513721032312-6a18a42c8763?w=152&amp;h=152&amp;fit=crop&amp;crop=faces" alt="">
          </div>
        </div>
      </div>
      <div id="messages" class="chat-messages">
        <div class="msg-left">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          Donec et nulla id enim tincidunt rutrum sit amet maximus nulla.
          <div class="chatinfo  text-right ">
          </div>
        </div>
        <div class="msg-right">
          Vestibulum efficitur elementum purus in tincidunt
          <div class="chatinfo text-right ">

          </div>
        </div>
        <span class="horario">Today, 2:35 pm</span>
        <div class="msg-left">
          Cras egestas, leo vel
          <div class="chatinfo text-right ">

          </div>
        </div>
        <div class="msg-right">
         Donec a mattis arcu
         <div class="chatinfo text-right ">

         </div>
       </div>
       <div class="msg-right">
         Ut elementum malesuada
         <div class="chatinfo text-right ">

         </div>
       </div>
       <div class="msg-right">
         Cras lobortis a dui a gravida.
         <div class="chatinfo text-right ">

         </div>
       </div>
       <div class="msg-left">
        fine
        <div class="chatinfo text-right ">

        </div>
      </div>
      <span class="horario">5:35 pm</span>
      <div class="msg-right">
        Donec convallis elementum justo nunc eget varius viverra, metus risus scelerisque ex,
        <div class="chatinfo text-right ">

        </div>
      </div>
      <div class="msg-left">
        Fusce et ullamcorper nisl. Morbi a finibus nisl. Nulla sagittis, nunc eget varius viverra, metus risus scelerisque ex, quis semper nulla orci at risus
        <div class="chatinfo text-right ">

        </div>
      </div>
    </div>
    <div class="input-group mt-10" style="padding-top: 10px;">
      <input type="search" placeholder="" aria-describedby="button-addon5" class="form-control">
      <div class="input-group-append">
        <button id="button-addon5" type="submit" class="btn btn-detail"><i class="fa fa-paper-plane" aria-hidden="true"></i></button>
      </div>
    </div>

  </div>

</div>
</div>
</template>

<script>
export default {
  name: 'chat',
  mounted () {
    window.scrollTo(0, document.body.scrollHeight)
    document.getElementById('messages').scrollTo(0, document.body.scrollHeight)
  }
}
</script>

<style>
.chat-messages {
  overflow-y: scroll;
  height: calc(100vh - 182px);
  background: #fff;
  font-size:14px;
}
</style>
